//
// Sobre layout
//
.membro {
  .member-image {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    img {
      object-fit: cover;
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
    }
  }
}

.ceo {
  background-image: url('https://res.cloudinary.com/dbdsdou2c/image/upload/c_fill,f_auto,g_north,h_600,q_auto,w_2560/ceo-2560x600_yxiocs.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__description {
    margin-top: 6rem;

    @include media-breakpoint-up(lg) {
      margin-top: 12rem;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 14rem;
    }
  }
}
