//
// Homepage layout
//
.banner-section {
    margin-top: -100px;
    @include media-breakpoint-down(md) {
        margin-top: 0px;
    }
}

.banner-slider {
    @include media-breakpoint-up(md) {
        .swiper-slide {
            padding-top: 6rem;
            background-size: cover;
            background-position: center;
        }
    }
}

.banner-slider .slider-item {
    @include media-breakpoint-up(md) {
        height: 600px;
    }
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}

.banner-slider .slider-item:before {
    position: absolute;
    content: "";
    top: 0;
    height: 100%;
    width: 100%;
}

.banner-slider .content {
    position: relative;
    z-index: 2;
    @media (max-width: 991.98px) {
        padding: 30px 10px 30px;
    }
}

.banner-slider .content.style .offer-text {
    letter-spacing: 7px;
    margin-bottom: 5px;
}

.banner-slider .content.style .tag-text {
    font-weight: normal;
}

.banner-slider .content h2 {
    text-transform: capitalize;
    color: #fff;
}

.banner-slider .content p {
    color: #fff;
    line-height: 1.25;
}

.banner-slider .content .btn-white {
    background: transparent;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    border: 1px solid #fff;
    transition: all 0.3s ease;
}

.banner-slider .content .btn-white:hover {
    background: #fff;
    color: #000;
}

.banner-slider .slick-dots {
    bottom: 20px;
}

.banner-slider .slick-dots li {
    margin: 0 3px;
}

.banner-slider .slick-dots li button:before {
    font-size: 15px;
}

.banner-slider .slick-dots li.slick-active button:before {
    color: #fff;
}

.banner-slider .slick-prev {
    left: 20px;
    z-index: 100;
}

.banner-slider .slick-next {
    right: 20px;
    z-index: 100;
}

.slick-dotted.slick-slider {
    margin-bottom: 0 !important;
}

section#blog {
    padding: 90px 0 60px;
    h3 a {
        color: $primary;
    }
    .post-blog {
        background: white;
        border-radius: 4px;
        overflow: hidden;
        img {
            margin-bottom: -30px;
        }
        span.data {
            background: #0f0f71;
            color: white !important;
            padding: 6px 14px;
            font-size: 1rem;
            border-radius: 5px 5px 0 0;
        }
    }
    .swiper-container {
        display: grid;
    }
}

.opcao {
    background-size: cover;
    display: grid;
    grid-template-rows: 0.9fr 0.3fr 1fr;
    justify-items: center;
    position: relative;
    h3 {
        max-width: 360px;
    }
}

.opcao_1 {
    background: #d1d1d1;
    background: linear-gradient(180deg, rgb(195 195 195 / 60%) 0%, #9b9b9b 100%);
}

.opcao_2 {
    background: #f39200;
    background: linear-gradient(180deg, #f39200c7 0%, rgba(190, 22, 34, 0.6839110644) 100%);
}

.opcao_3 {
    background: #3861b4;
    background: linear-gradient(180deg, rgb(200 211 0 / 56%) 0%, #123274 100%);
}

.styled-pagination {
    padding-top: 50px;
}

.styled-pagination ul {
    padding-left: 0;
}

.styled-pagination ul li {
    position: relative;
    display: inline-block;
    margin-right: 5px;
}

.styled-pagination ul li a {
    position: relative;
    display: block;
    line-height: 50px;
    font-size: 16px;
    width: 50px;
    height: 50px;
    color: #777777;
    font-weight: 500;
    text-align: center;
    background: #f4f4f4;
    border-radius: 4px;
    transition: all 500ms ease;
}

.styled-pagination ul li a.prev,
.styled-pagination ul li a.next {
    font-size: 18px;
}

.styled-pagination ul li a:hover,
.styled-pagination ul li a.active {
    color: #ffffff;
    background: $secondary;
    transition: all 500ms ease;
}

@media only screen and (max-width: 991px) {
    .main-header .search-box {
        display: none;
    }
    .header-uper .logo {
        float: none !important;
        text-align: center;
        margin-bottom: 20px;
        width: 100%;
    }
    .main-header.style-two .logo {
        float: none !important;
        text-align: center;
        margin-bottom: 20px;
    }
    .main-header.style-two .search-box-btn {
        top: 95px !important;
    }
    .fact-counter .column .item {
        margin-bottom: 40px !important;
    }
    .contact-area {
        margin-top: 70px !important;
        padding-left: 0px !important;
    }
    .footer-main .footer-top .social-links {
        margin-left: 0px !important;
    }
    .footer-bottom .footer-bottom-link {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .main-header {
        margin-bottom: 0px !important;
    }
    .main-header .logo {
        position: absolute !important;
        top: -5px;
        left: 30%;
    }
    .search_option {
        position: absolute !important;
        top: 2px;
        right: 5px;
    }
    .footer-main .footer-top .menu-link {
        margin-left: 0px !important;
    }
    .main-header.style-two .search-box-btn {
        top: 15px !important;
    }
    .footer-main .footer-top .gallery-widget {
        margin-left: 0px !important;
    }
    .service-tab-section .tab-list-column .tab-list,
    .service-tab-section .tab-content .inner-box {
        margin-left: 0px !important;
    }
    .header-uper .right-side {
        float: none !important;
        text-align: center !important;
    }
    .header-uper .contact-info {
        display: none;
    }
    .header-uper .logo {
        margin-bottom: 15px !important;
    }
    .header-uper .contact-info .item {
        text-align: left !important;
        margin-left: 40px !important;
        margin-right: 0px !important;
    }
    .header-uper .link-btn {
        float: none !important;
        margin-left: 0px !important;
    }
}

.whatsapp-fixed-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    box-sizing: border-box;
    padding: 0.75rem;
    height: 4rem;
    width: 4rem;
    font-size: 2rem;
    border-radius: 100%;
    color: #fff !important;
    background-color: #25d366;
    z-index: 1050;
}

@media only screen and (max-width: 467px) {
    .main-header .logo {
        position: relative !important;
        top: 0px !important;
        left: 0px !important;
    }
    .main-header.style-two .search-box-btn {
        top: 95px !important;
    }
}

@media only screen and (max-width: 350px) {
    .feature-section .contact-info .item {
        padding-left: 50px !important;
        padding-right: 0px !important;
    }
    .feature-section .contact-info .icon-box {
        left: 10px !important;
    }
}