// 
// Typography base/normalize
// 
s {
    text-decoration: none !important;
    color: $primary;
}

h1,
.h1 {
    color: $primary;
}

h2,
.h2 {
    color: $primary;
}

h5 {
    color: $primary;
}

h1,
h2,
h3 {
    span {
        display: inline-block;
        width: fit-content;
        @include media-breakpoint-down(md) {
            display: initial;
        }
    }
}

body {
    background-image: url(https://res.cloudinary.com/dbdsdou2c/image/upload/f_auto,q_auto:good/v1614610336/Planus-Fundo_adah1y_kdiyml.jpg);
    background-repeat: repeat;
    background-position: center;
}

p {
    @include font-size($font-size-sm);
}

a.post {
    text-decoration: none !important;
    color: $gray-900;
}

.align-items-center {
    display: flex;
    align-items: center;
}

.p-big {
    font-weight: lighter;
    font-size: 2.125rem;
    line-height: 1.25;
}

.big-title {
    font-size: 3rem;
    font-weight: 900;
}

.text-orange {
    color: $orange;
}

.text-cyan {
    color: $cyan;
}

.text-green {
    color: $green;
}

.text-red {
    color: $red;
}

.color-gray {
    color: $gray-600;
    &:hover {
        color: $gray-700;
    }
}