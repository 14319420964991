.opcao_i {
    background-size: cover;
    display: grid;
    grid-template-rows: 1fr;
    justify-items: center;
    position: relative;
    h2 {
        font-size: 2rem;
    }
    h3 {
        min-height: 90px;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        width: 100%;
        justify-content: center;
    }
    img.image-bg {
        z-index: -1;
        filter: grayscale(100%);
    }
}

.box-icon {
    display: grid;
    grid-template-rows: 0.9fr 0.3fr 1fr;
    justify-items: center;
}

section#ebook {
    background-size: cover;
    background-position: center;
}

#depoimentos {
    img {
        border-radius: 100%;
        object-fit: cover;
        height: 150px;
        width: 150px;
    }
}

.box {
    .bg-image {
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: wheat;
        position: relative;
        z-index: 111;
        @media (min-width: 767px) {
            height: 100%;
        }
    }
}