//
// Base
//
.btn {
  font-weight: 600;
}

a {
  text-decoration: none !important;
}

.show {
  display: block !important;
}

.fade {
  display: none !important;
}

.progress {
  position: relative;
  height: 24px;
  .progress-bar {
    text-align: left;
    strong {
      padding-left: 8px;
    }
  }
  e {
    position: absolute;
    right: 2px;
    top: 2px;
    font-weight: 700;
    color: $secondary;
    background-color: white !important;
    z-index: 11111;
    width: 38px;
    height: 20px;
    line-height: 1.5;
    border-radius: 3px;
    text-align: center;
  }
}

.desktop {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.mobile {
  display: none;
  position: relative;
  @include media-breakpoint-down(md) {
    display: inherit;
  }
}

.vertical-center {
  display: flex;
  align-items: center;
}

.section.dark {
  color: $white;
  background-color: $dark;
}

.section-title h3 {
  font-weight: 700;
}

.section-title span {
  font-weight: 400;
}

.strike-through {
  text-decoration: line-through;
}

.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

.small-container {
  max-width: 680px;
  margin: 0 auto;
}

.anim-3,
.anim-3-all * {
  transition: all 0.3s ease;
}

.anim-5,
.anim-5-all * {
  transition: all 0.5s ease;
}

.anim-7,
.anim-7-all * {
  transition: all 0.7s ease;
}

.section {
  padding: 3rem 0;
  position: relative;
  @include media-breakpoint-down(md) {
    padding: 1rem 0;
  }
}

.p-0-mobile {
  @include media-breakpoint-down(md) {
    padding: 0 !important;
  }
}

form#contact-form button.btn {
  width: inherit;
  margin: 0 auto;
  min-width: 200px;
}

#loading {
  display: none;
}

.page-action-bar {
  background-color: $white;
  z-index: 2;
  position: relative;
  @include media-breakpoint-down(md) {
    display: none;
  }
  a {
    display: flex;
    align-items: center;
    color: $primary;
    text-decoration: none;
    @include font-size($font-size-sm);
    svg {
      margin-right: 0.25rem;
    }
    &:hover {
      text-decoration: $link-decoration;
    }
  }
}

.page-header {
  position: sticky;
  top: 0;
  z-index: $zindex-sticky;
  width: 100%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  @include media-breakpoint-down(md) {
    background: $primary;
  }
  &.page-header--transparent {
    @include media-breakpoint-up(md) {
      background-color: transparent;
    }
  }
}

.page-body {
  position: relative;
  .main-content {
    max-width: 100vw;
  }
}

.page-footer {
  @include font-size($font-size-sm);
  padding-top: 2rem;
  padding-bottom: 1rem;
  color: $light;
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
  &__widgets {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: grid;
    grid-gap: 2rem 3rem;
    grid-template-columns: 1fr 1fr 1fr;
    @include media-breakpoint-down(sm) {
      padding-top: 2rem;
      padding-bottom: 2rem;
      grid-gap: 1rem;
      grid-template-columns: 1fr;
    }
  }
  p,
  a,
  li {
    color: #fff;
  }
  img.footer-widget__image.logotipo {
    margin-top: -20px;
  }
  form#form_newsletter {
    position: relative;
    input#email {
      position: absolute;
      border-radius: 40px;
      background: white;
      border: 0;
      padding: 10px 17px;
      width: 100%;
      outline: none !important;
    }
    input[type='submit'] {
      position: absolute;
      border-radius: 40px;
      background: #243572;
      border: 0;
      padding: 8px 14px;
      top: 2px;
      right: 2px;
      color: white;
    }
  }
}

.copyright-area {
  padding: 0.5rem 0;
  svg {
    fill: $white;
  }
  &__container {
    display: flex;
    justify-content: space-between;
  }
}

.text-box-orange {
  background: $orange;
  padding: 0 14px;
  color: white;
  font-weight: 700;
}

.text-box-green {
  background: $green;
  padding: 0 14px;
  color: white;
  font-weight: 700;
}

.text-box-cyan {
  background: $cyan;
  padding: 0 10px;
  color: white;
  font-weight: 700;
}

.text-box-green {
  background: $green;
  padding: 0 10px;
  color: white;
  font-weight: 700;
}

.text-box-red {
  background: $red;
  padding: 0 10px;
  color: white;
  font-weight: 700;
}

.text-box-gray {
  background: $gray-500;
  padding: 0 10px;
  color: white;
  font-weight: 700;
}

.text-box-blue {
  background: $primary;
  padding: 0 10px;
  color: white;
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-cyan {
  background: $cyan;
}

.bg-orange {
  background: $orange;
}

.bg-green {
  background: $green;
}

.box-color {
  width: 100%;
}

.border-box-blue {
  border: 3px solid $cyan;
  &:hover {
    background: $cyan;
  }
}

.border-box-orange {
  border: 3px solid $orange;
  &:hover {
    background: $orange;
  }
}

.border-box-green {
  border: 3px solid $green;
  &:hover {
    background: $green;
  }
}

a.btn {
  border-radius: 0;
}

.btn-blue {
  border: 3px solid $cyan;
  color: $cyan;
  text-transform: inherit;
  padding: 20px 30px !important;
  &:hover {
    background: $cyan;
    color: white;
  }
}

.btn-dark-blue {
  border: 3px solid $primary;
  color: $primary;
  text-transform: inherit;
  padding: 20px 30px !important;
  &:hover {
    background: $primary;
    color: white;
  }
}

.btn-orange {
  border: 3px solid $orange;
  color: $orange;
  text-transform: inherit;
  padding: 20px 30px;
  &:hover {
    background: $orange;
    color: white;
  }
}

.btn-red {
  border: 3px solid $red;
  color: $red;
  text-transform: inherit;
  padding: 20px 30px;
  &:hover {
    background: $red;
    color: white;
  }
}

.btn-green {
  border: 3px solid $green;
  color: $green;
  text-transform: inherit;
  padding: 20px 30px !important;
  &:hover {
    background: $green;
    color: white;
  }
}

.btn-gray {
  border: 3px solid $gray-500;
  color: $gray-700;
  text-transform: inherit;
  padding: 20px 30px !important;
  background: none;
  &:hover {
    background: $gray-500;
    border: 3px solid $gray-500;
    color: white;
  }
}

a.page-link {
  background: #f39200 !important;
  color: white !important;
  border: 0 !important;
  padding: 10px 20px;
}

.youtube-modal-toggler > svg:hover {
  opacity: 0.5;
}

form.newsletter {
  background: $cyan;
  color: white;
  border: 2px solid $cyan;
  padding: 5px 2px;
  position: relative;
  display: inline-block;
  @media (max-width: 991.98px) {
    padding: 10px;
  }
  input {
    width: 80%;
    float: left;
    border-radius: 0;
    border: 0;
    font-size: 1.1875rem;
    @media (max-width: 991.98px) {
      width: 100%;
      margin-bottom: 6px;
    }
  }
  .btn {
    width: 20%;
    padding: 11px 0 10px;
    border: 0;
    background: #e4e4e4;
    border-radius: 0;
    text-transform: inherit;
    font-weight: normal;
    line-height: 1.25;
    font-size: 1.1875rem;
    color: #797979;
    @media (max-width: 991.98px) {
      width: 50%;
      margin: 5px auto 0;
      display: block;
    }
  }
  .email {
    width: 50%;
    float: left;
    padding: 8px 0;
    font-size: $font-size-lg;
    @media (max-width: 991.98px) {
      font-size: $font-size-sm;
      width: 100%;
      float: none;
    }
  }
  .field-email {
    width: 50%;
    float: left;
    @media (max-width: 991.98px) {
      width: 100%;
      float: none;
    }
  }
  .form-spinner.spinner-border {
    width: 30px !important;
    height: 30px;
    margin: 3px 5px 0 !important;
  }
  .form-alerts {
    width: 50%;
    position: absolute;
    left: 0;
    @media (max-width: 991.98px) {
      width: 100%;
    }
    div {
      line-height: 1.15 !important;
      width: 98%;
      margin: 0 5px !important;
      text-align: center;
    }
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

footer {
  p {
    font-weight: lighter;
  }
  a {
    text-decoration: none;
  }
  .copyright-area__container p {
    font-size: 1rem;
  }
}

.orange {
  background: $orange !important;
  border: 2px solid $orange !important;
  .btn {
    color: $orange !important;
  }
}

.gray {
  background: $gray-600 !important;
  border: 2px solid $gray-600 !important;
  .btn {
    color: $gray-600 !important;
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111111111;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #00133aa6;
  display: none;
  .modal-dialog {
    width: 60%;
    margin: 0 auto;
    position: relative;
    margin-top: 2rem;
    max-width: inherit !important;
    .modal-content {
      overflow: hidden;
      @media (max-width: 767px) {
        .modal-title {
          font-size: 15px;
        }
        .modal-body {
          margin: 5px 10px !important;
        }
      }
    }
    .modal-body {
      margin-top: 0;
    }
    @media (max-width: 991.98px) {
      width: 92%;
      overflow-y: scroll;
      margin-top: 12px;
      .modal-body {
        margin-top: 1rem;
      }
    }
    button.close {
      position: absolute;
      right: 0;
      top: 0;
      border: 0;
      padding: 5px 10px;
      line-height: 1;
      font-size: 1.5rem;
      color: white;
      background: #bdc4ce;
      z-index: 1111111;
    }
  }
}

.image-bg {
  object-fit: cover;
  object-position: center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.whatsapp-fixed-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  box-sizing: border-box;
  padding: 0.75rem;
  height: 4rem;
  width: 4rem;
  font-size: 2rem;
  line-height: 1;
  border-radius: 100%;
  color: #fff;
  background-color: #25d366;
  z-index: 1050;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 1px 3px 1px rgba(60, 64, 67, 0.16);
  transition: box-shadow 0.2s ease;
  svg {
    width: 83% !important;
    height: auto;
  }
}

footer {
  form.newsletter button {
    padding: 10px 30px !important;
  }
  .footer-widget .btn {
    background: #f39200;
    color: white;
    padding: 10px 20px;
  }
}

.numbers {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 996px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.number-counter {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__number {
    display: flex;
    justify-content: center;
    color: $primary;
    font-weight: $font-weight-bold;
    @include font-size($h1-font-size);
  }

  &__text {
    @include font-size($h4-font-size);
  }
}

.line-list {
  list-style: none;
  padding: 0;

  li::before {
    content: '\2192';
    margin-right: 4px;
  }
}

.bg-texture {
  background-image: url('https://res.cloudinary.com/dbdsdou2c/image/upload/f_auto,q_auto/fundo-texturizado-1920x1080_vyshsh.png');
}
