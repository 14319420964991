//
// Swiper styles
//

$themeColor: $primary !default;
$colors: (
  'white': $white,
  'black': $black,
) !default;
@import '../../../node_modules/swiper/swiper';
@import '../../../node_modules/swiper/components/pagination/pagination';
@import '../../../node_modules/swiper/components/navigation/navigation';

.swiper-slide {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.swiper-button-prev {
  position: absolute;
  left: 0;
  width: 40px;
  height: 16%;
  top: 42%;
  z-index: 1111111111;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.swiper-button-next {
  position: absolute;
  right: 0;
  width: 40px;
  height: 16%;
  top: 42%;
  z-index: 1111111111;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.partners-slider .swiper-pagination {
  bottom: 0;
}
