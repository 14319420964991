//
// Single post layout
//
.post-article {
    padding-bottom: 3rem;
    @include make-container();
    max-width: 680px;
    p,
    ul,
    ol {
        margin-top: $article-paragraph-margin-top;
        margin-bottom: $article-paragraph-margin-bottom;
    }
    ul,
    ol {
        padding-left: $article-list-padding-left;
        margin-left: $article-list-margin-left;
    }
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        margin-top: $article-headings-margin-top;
        margin-bottom: $article-headings-margin-bottom;
    }
    h1,
    .h1 {
        @include font-size($article-h1-font-size);
        font-weight: $article-h1-font-weight;
    }
    h2,
    .h2 {
        @include font-size($article-h2-font-size);
        font-weight: $article-h2-font-weight;
    }
    h3,
    .h3 {
        @include font-size($article-h3-font-size);
        font-weight: $article-h3-font-weight;
    }
    h4,
    .h4 {
        @include font-size($article-h4-font-size);
        font-weight: $article-h4-font-weight;
    }
    h5,
    .h5 {
        @include font-size($article-h5-font-size);
        font-weight: $article-h5-font-weight;
    }
    h6,
    .h6 {
        @include font-size($article-h6-font-size);
        font-weight: $article-h6-font-weight;
    }
    figure,
     :not(figure) img {
        margin-top: $article-image-margin-top;
        margin-right: $article-image-margin-right;
        margin-bottom: $article-image-margin-bottom;
        margin-left: $article-image-margin-left;
        max-width: calc(100% + #{-$article-image-margin-left} + #{-$article-image-margin-right});
        position: relative;
        left: calc(50% + #{-$article-image-margin-left});
        transform: translateX(-50%);
        @include media-breakpoint-down(lg) {
            max-width: 100%;
            position: static;
            left: 0;
            transform: none;
            margin-left: 0;
            margin-right: 0;
        }
    }
    figure img {
        max-width: 100%;
        height: auto;
    }
    p>figure,
    p>img {
        margin-top: 0;
    }
    a {
        color: $article-link-color;
    }
    figure.post-article__thumbnail {
        position: relative;
        width: 100%;
        padding-top: 42.8571428571%;
        @include media-breakpoint-down(lg) {
            padding-top: 56.25%;
        }
        &::before {
            display: block;
            content: "";
        }
        img {
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .post-article__title {
        margin-top: $article-headings-margin-top / 2;
    }
    .post-article__metadata {
        display: flex;
        justify-content: space-between;
        margin-top: $article-paragraph-margin-top;
    }
    #disqus_thread {
        margin-top: 3rem;
    }
}