// 
// Youtube modal with thumbnail toggler
// 
.youtube-modal-toggler {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none !important;
    &__thumb {
        filter: brightness(0.8);
        transition: $transition-base;
    }
    &__play {
        position: absolute;
        height: 5rem;
        width: 5rem !important;
        color: $primary;
        transition: $transition-base;
    }
    &:hover {
        .youtube-modal-toggler__thumb {
            filter: brightness(1);
        }
        .youtube-modal-toggler__play {
            color: adjust-color($color: $primary, $lightness: 10%);
        }
    }
}

.youtube-modal {
    .close {
        position: absolute;
        top: -3rem;
        right: -3rem;
        -webkit-appearance: none;
        background-color: transparent;
        color: $light;
        border: 0;
        @include font-size(2rem);
    }
    .embed-responsive {
        background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgYXJpYS1sYWJlbGxlZGJ5PSJsb2FkaW5nLWFyaWEiIHZpZXdCb3g9IjAgMCA4MDAgNDUwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj4KICA8dGl0bGUgaWQ9ImxvYWRpbmctYXJpYSI+TG9hZGluZy4uLjwvdGl0bGU+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgY2xpcC1wYXRoPSJ1cmwoI2NsaXAtcGF0aCkiIHN0eWxlPSJmaWxsOiB1cmwoJyNmaWxsJyk7Ij48L3JlY3Q+CiAgPGRlZnM+CiAgICA8Y2xpcFBhdGggaWQ9ImNsaXAtcGF0aCI+CiAgICAgIDxyZWN0IHg9IjAiIHk9IjAiIHJ4PSIwIiByeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgLz4KICAgIDwvY2xpcFBhdGg+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImZpbGwiPgogICAgICA8c3RvcCBvZmZzZXQ9IjAuNTk5OTY0IiBzdG9wLWNvbG9yPSIjZjNmM2YzIiBzdG9wLW9wYWNpdHk9IjEiPgogICAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIgdmFsdWVzPSItMjsgLTI7IDEiIGtleVRpbWVzPSIwOyAwLjI1OyAxIiBkdXI9IjJzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSI+PC9hbmltYXRlPgogICAgICA8L3N0b3A+CiAgICAgIDxzdG9wIG9mZnNldD0iMS41OTk5NiIgc3RvcC1jb2xvcj0iI2Q0ZDRkNCIgc3RvcC1vcGFjaXR5PSIxIj4KICAgICAgICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJvZmZzZXQiIHZhbHVlcz0iLTE7IC0xOyAyIiBrZXlUaW1lcz0iMDsgMC4yNTsgMSIgZHVyPSIycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgICA8c3RvcCBvZmZzZXQ9IjIuNTk5OTYiIHN0b3AtY29sb3I9IiNmM2YzZjMiIHN0b3Atb3BhY2l0eT0iMSI+CiAgICAgICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0ib2Zmc2V0IiB2YWx1ZXM9IjA7IDA7IDMiIGtleVRpbWVzPSIwOyAwLjI1OyAxIiBkdXI9IjJzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSI+PC9hbmltYXRlPgogICAgICA8L3N0b3A+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KPC9zdmc+Cg==');
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive-16by9 {
    padding-top: 56.25%;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}